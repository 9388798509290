

html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
img,
pre,
form,
fieldset {
  margin: 0;
  padding: 0;
}
img,
fieldset {
  border: 0;
}

:root {
  --app-height: 100%;
  --vh: 1px
}


body,
html {
  height: 100%;
  height: var(--app-height);
  width: 100%;
}

body {
  background-color: #fff;
  color: #172B4D;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto
}

/* Default margins */
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
  margin: 12px 0 0 0;
}

/* Links */
a {
  color: #0052CC;
  text-decoration: none;
}
a:hover {
  color: #0065FF;
  text-decoration: underline;
}
a:active {
  color: #0747A6;
}
a:focus {
  outline: 2px solid #4C9AFF;
  outline-offset: 2px;
}

/* Headings */
h1 {


  font-size: 2.0714285714285716em;
  font-style: inherit;
  line-height: 1.103448275862069;
  color: #172B4D;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-top: 40px;

}
h2 {


  font-size: 1.7142857142857142em;
  font-style: inherit;
  line-height: 1.1666666666666667;
  color: #172B4D;
  font-weight: 500;
  letter-spacing: -0.01em;
  margin-top: 40px;

}
h3 {


  font-size: 1.4285714285714286em;
  font-style: inherit;
  line-height: 1.2;
  color: #172B4D;
  font-weight: 500;
  letter-spacing: -0.008em;
  margin-top: 28px;

}
h4 {


  font-size: 1.1428571428571428em;
  font-style: inherit;
  line-height: 1.25;
  color: #172B4D;
  font-weight: 600;
  letter-spacing: -0.006em;
  margin-top: 24px;

}
h5 {


  font-size: 1em;
  font-style: inherit;
  line-height: 1.1428571428571428;
  color: #172B4D;
  font-weight: 600;
  letter-spacing: -0.003em;
  margin-top: 16px;

}
h6 {


  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: #172B4D;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;

}

/* Lists */
ul,
ol,
dl {
  padding-left: 40px;
}
[dir='rtl']ul,
[dir='rtl']ol,
[dir='rtl']dl {
  padding-left: 0;
  padding-right: 40px;
}

dd,
dd + dt,
li + li {
  margin-top: 4px;
}
ul ul:not(:first-child),
ol ul:not(:first-child),
ul ol:not(:first-child),
ol ol:not(:first-child) {
  margin-top: 4px;
}

/* remove top margin for first element */
p:first-child,
ul:first-child,
ol:first-child,
dl:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
blockquote:first-child,
pre:first-child,
form:first-child,
table:first-child {
  margin-top: 0;
}

/* Quotes */
blockquote,
q {
  color: inherit;
}
blockquote {
  border: none;
  padding-left: 40px;
}
[dir='rtl'] blockquote {
  padding-left: 0;
  padding-right: 40px;
}

blockquote::before,
q::before {
  content: '\201C';
}

blockquote::after,
q::after {
  content: '\201D';
}

blockquote::before {
  float: left;
  /* to keep the quotes left of any child elements like blockquote > p */
  margin-left: -1em;
  text-align: right;
  width: 1em;
}
[dir='rtl'] blockquote::before {
  float: right;
  margin-right: -1em;
  text-align: left;
}

blockquote > :last-child {
  display: inline-block; /* so the quotes added via pseudos follow it immediately. */
}

/* Other typographical elements */
small {


  font-size: 0.7857142857142857em;
  font-style: inherit;
  line-height: 1.4545454545454546;
  color: #6B778C;
  font-weight: 700;
  margin-top: 16px;

}

code,
kbd {
  font-family: "SFMono-Medium", "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Consolas, Courier, monospace;
}

var,
address,
dfn,
cite {
  font-style: italic;
}

abbr {
  border-bottom: 1px #ccc dotted;
  cursor: help;
}


table {
  border-collapse: collapse;
  width: 100%;
}

thead,
tbody,
tfoot {
  border-bottom: 2px solid #DFE1E6;
}

td,
th {
  border: none;
  padding: 4px 8px;
  text-align: left;
}

th {
  vertical-align: top;
}

td:first-child,
th:first-child {
  padding-left: 0;
}

td:last-child,
th:last-child {
  padding-right: 0;
}

caption {


  font-size: 1.4285714285714286em;
  font-style: inherit;
  line-height: 1.2;
  color: #172B4D;
  font-weight: 500;
  letter-spacing: -0.008em;
  margin-top: 28px;

  margin-bottom: 8px;
  text-align: left;
}


/* IE11 doesn't support <template> elements which shouldn't be displayed */
template {
  display: none;
}

/* IE11 and some older browsers don't support these elements yet and treat them as display: inline; */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* Suppress the ugly broken image styling in Firefox */
@-moz-document url-prefix() {
  img {
    font-size: 0;
  }
  img:-moz-broken {
    font-size: inherit;
  }
}


.assistive {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}


.first-header {
  padding-left: 0;
  padding-right: 50px;
}

.single-select {
  min-width: 250px;
}

.input-range__slider {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #0052CC;
  border: 1px solid #0052CC;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 16px;
  margin-left: -8px;
  margin-top: -9px;
  outline: none;
  position: absolute;
  top: 50%;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 16px; }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(0, 82, 204, 0.2); }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
            transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #172B4D;
  font-size: 14px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
  display: none; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -1.8rem; }

.input-range__label-container {
  left: -50%;
  top: -10px;
  position: relative; }
  .input-range__label--max .input-range__label-container {
    left: 50%; }

.input-range__track {
  background: #D8D8D8;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 2px;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }
  .input-range--disabled .input-range__track {
    background: #D8D8D8; }

.input-range__track--background {
  left: 0;
  margin-top: 10px;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #0052CC; }

.input-range {
  height: 16px;
  position: relative;
  margin-top: 24px;
  margin-left: 5px;
  width: calc(100% - 5px); }

