@import 'input-range-variables';
@import 'input-range-slider';
@import 'input-range-label';
@import 'input-range-label-container';
@import 'input-range-track';

.input-range {
  height: $input-range-slider-height;
  position: relative;
  margin-top: 24px;
  margin-left: 5px;
  width: calc(100% - 5px);
}
